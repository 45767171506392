import {Link} from "react-router-dom";

export default function SignUp() {
    return (
        <div>
            <div>Sign Up</div>

            <form>
                <div>
                    <label>Name</label>
                </div>
                <div>
                    <label>Email</label>
                </div>
                <div>
                    <label>Password</label>
                </div>
                <div>
                    <label>Confirm Password</label>
                </div>

                <Link to={"/dashboard"}>Sign Up</Link>
            </form>
        </div>
    );
}
