import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Root from 'routes/root';
import Index from 'routes/index';
import Login from 'routes/login';
import SignUp from 'routes/signup';
import TemplateIndex from 'routes/templates/index';
import TemplateShow from 'routes/templates/show';
import DashboardRoot from 'routes/dashboard/root';
import DashboardIndex from 'routes/dashboard/index';
import DashboardEdit from 'routes/dashboard/edit';

const router = createBrowserRouter([
    {
        element: <Root />,
        children: [
            {
                path: "/",
                element: <Index />,
            },
            {
                path: "/login",
                element: <Login />,
            },
            {
                path: "/signup",
                element: <SignUp />,
            },
            {
                path: "/templates",
                element: <TemplateIndex />,
            },
            {
                path: "/templates/:id",
                element: <TemplateShow />,
            },
        ],
    },
    {
        element: <DashboardRoot />,
        children: [
            {
                path: "/dashboard",
                element: <DashboardIndex />,
            },
            {
                path: "/dashboard/:id/edit",
                element: <DashboardEdit />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
