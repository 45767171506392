import {Link} from "react-router-dom";
import "css/index.css";
import backgroundImage from "background.jpg";

export default function Index() {
    return (
        <div style={{backgroundImage: `url(${backgroundImage})`}}>
            <section className={"vh-100 d-flex"}>
                <h1 className={"h1"}>OwnPage</h1>
                <div>Just a few clicks, you can get your web page for everything.</div>

                <div>
                    <Link to={"/templates"}>Get your own page NOW!</Link>
                </div>
                <div>
                    <Link to={"/signup"}>Sign Up</Link>
                </div>
                <div>For Free. No credit card required</div>
            </section>

            <section>
                <div>Free</div>
                <div>Simple</div>
                <div>Responsive</div>
                <div>Beautify templates</div>
            </section>

            <section>
                <div>Free</div>
            </section>

            <section>
                <div>HKD 140 / year</div>
            </section>

            <section>
                <div>Get Started</div>
                <div>Get your page</div>
            </section>
        </div>
    );
}
