import {Link} from "react-router-dom";

export default function Index() {
    return (
        <div>
            <div>Templates</div>

            <div><Link to={"/templates/1"}>Show</Link></div>
        </div>
    );
}
